
import { defineComponent, PropType } from 'vue';
import { Select } from 'ant-design-vue';
import FilterSelection from './FilterSelection.vue';

const { Option } = Select;

export default defineComponent({
  name: 'SeasonalityFilter',
  components: {
    FilterSelection,
    Option,
  },
  props: {
    seasons: {
      type: Array as PropType<string[]>,
      default: ['All', 'Harvest Season', 'Lean Season', 'Not applicable'] as string[],
    },
    module: {
      type: String,
      default: 'SurveysFilter',
    },
  },
  computed: {
    getSeasonality() {
      return this.$store.getters[`${this.module}/getSeasonality`];
    },
  },
  methods: {
    getSeasonalityName(name: string) {
      let n = name;
      if (name === 'Not applicable') n = 'Not Applicable';
      return n;
    },
    select(key : string): void {
      if (key === 'All') {
        this.clear();
      } else {
        this.$store.dispatch(`${this.module}/updateSeasonality`, key);
      }
    },
    clear(): void {
      this.$store.dispatch(`${this.module}/updateSeasonality`, '');
      this.$emit('clear');
    },
  },
  emits: ['clear'],
});
