
import { defineComponent, PropType } from 'vue';
import FilterSelection from './FilterSelection.vue';

export default defineComponent({
  name: 'SurveyTypeFilter',
  components: {
    FilterSelection,
  },
  props: {
    surveyTypes: {
      type: Array as PropType<string[]>,
      default: ['All', 'Full Smart', 'NNS', 'Rapid Smart', 'SENS', 'Other'] as string[],
    },
    module: {
      type: String,
      default: 'surveysFilter',
    },
  },
  computed: {
    getSurveyType() {
      return this.$store.getters[`${this.module}/getSurveyType`];
    },
  },
  methods: {
    select(key : string): void {
      if (key === 'All') {
        this.clear();
      } else {
        this.$store.dispatch(`${this.module}/updateSurveyType`, key);
      }
    },
    clear(): void {
      this.$store.dispatch(`${this.module}/updateSurveyType`, '');
      this.$emit('clear');
    },
  },
  emits: ['clear'],
});
