
import { defineComponent } from 'vue';
import { locationProps, organizationOptionProps } from '@/utils/request';
import { Button } from 'ant-design-vue';
import GeographicLocation from '@/components/Filters/GeographicLocation/index.vue';
import DateRangeFilter from '@/components/Filters/DateRangeFilter.vue';
import AgeGroupFilter from '@/components/Filters/AgeGroupFilter.vue';
import SeasonalityFilter from '@/components/Filters/SeasonalityFilter.vue';
import OrganizationFilter from '@/components/Filters/OrganizationFilter.vue';
import SurveyTypeFilter from '@/components/Filters/SurveyTypeFilter.vue';

export default defineComponent({
  name: 'SurveysFilters',
  components: {
    GeographicLocation,
    DateRangeFilter,
    AgeGroupFilter,
    SeasonalityFilter,
    OrganizationFilter,
    SurveyTypeFilter,
    Button,
  },
  props: {
    filterClicked: {
      type: Boolean,
      default: false,
    },
    module: {
      type: String,
      default: 'surveysFilter',
    },
  },
  computed: {
    getQueryDiff() {
      const q = this.$store.getters[`${this.module}/getQueryDiff`];
      return q;
    },
    getLocationProps() {
      const options: locationProps[] = this.$store.getters[`${this.module}/getLocationOptions`];
      let x:(string|undefined)[] = [];
      let locations: {value: string, label:string;}[] = [];
      const scope = this.$store.getters[`${this.module}/getGeographicScope`];
      if (scope === 'Regional') {
        options.forEach((opt: locationProps) => {
          if (opt?.region && !x?.includes(opt?.region)) x.push(opt.region);
        });
        x = x.filter((o: string|undefined) => (o));
        x = x.sort((a, b) => ((a as string) > (b as string) ? 1 : -1));
        locations = (x as string[]).map((v) => ({ value: v, label: 'Region' }));
      } else if (scope === 'National') {
        options.forEach((opt: locationProps) => {
          if (opt?.title && !x?.includes(opt?.title)) x.push(opt.title);
        });
        x = x.filter((o: string|undefined) => (o));
        x = x.sort((a, b) => ((a as string) > (b as string) ? 1 : -1));
        locations = (x as string[]).map((v) => ({ value: v, label: 'Country' }));
      } else if (scope === 'Sub-National') {
        let y: (string[]|undefined)[] = options.flatMap((opt: locationProps) => {
          const children = opt?.children;
          let flatOptOne:(string[]|undefined)[] = [];
          let flatOptTwo:(string[]|undefined)[] = [];
          let flatOptThree:(string[]|undefined)[] = [];
          if (children && children.length > 0) {
            // admin 1
            flatOptOne = children.map((child: locationProps) => ([child.title, opt.title]));
            // admin 2
            flatOptTwo = children.flatMap((adminOne: locationProps) => {
              const adminOneChildren = adminOne?.children;
              let adminTwoChildren:string[][] = [];
              if (adminOneChildren && adminOneChildren.length > 0) {
                adminTwoChildren = adminOneChildren.map((adminTwo: locationProps) => (
                  // add only unique
                  flatOptOne.some((i) => (i && i[0] !== adminTwo.title)) ? [adminTwo.title, opt.title] : []
                ));
              }
              return adminTwoChildren;
            });
            // admin 3
            flatOptThree = children.flatMap((adminOne: locationProps) => {
              const adminOneChildren = adminOne?.children;
              let adminThreeChildren:string[][] = [];
              if (adminOneChildren && adminOneChildren.length > 0) {
                adminThreeChildren = adminOneChildren.flatMap((adminTwo: locationProps) => {
                  let c:string[][] = [];
                  const adminTwoChildren = adminTwo?.children;
                  if (adminTwoChildren && adminTwoChildren.length > 0) {
                    c = adminTwoChildren.map((adminThree: locationProps) => (
                      (flatOptTwo.some((i) => (i && i[0] !== adminThree.title)) && flatOptOne.some((i) => (i && i[0] !== adminThree.title))) ? [adminThree.title, opt.title] : []
                    ));
                  }
                  return c;
                });
              }
              return adminThreeChildren;
            });
          }
          return [...flatOptOne, ...flatOptTwo, ...flatOptThree];
        });
        y = y.filter((o: string[]|undefined) => (o && o[0] && o[1]));
        y = (y as string[][]).sort((a, b) => ((a[0]) > (b[0]) ? 1 : -1));
        locations = (y as string[][]).map((a) => ({ value: a[0], label: a[1] }));
      }
      return locations;
    },
    getOrganizationOptions() {
      const options: organizationOptionProps[] = this.$store.getters[`${this.module}/getOrganizationOptions`];
      let x = options.map((opt: organizationOptionProps) => (opt?.name));
      x = x.filter((opt) => (opt && opt.trim()));
      x = x.sort((opt1, opt2) => (opt1.toLowerCase() > opt2.toLowerCase() ? 1 : -1));
      return x;
    },
  },
  methods: {
    onClick() {
      this.$store.dispatch(`${this.module}/updateData`);
    },
    onClear() {
      if (this.getQueryDiff < 0) this.$store.dispatch(`${this.module}/updateData`);
    },
  },
});
