
import { defineComponent } from 'vue';
import message from 'ant-design-vue/lib/message';
import { Button, Spin } from 'ant-design-vue';
import SurveysFilters from '@/components/Filters/SurveysFilters/index.vue';
import Header from '@/components/Surveys/Header.vue';

type pages = 'anthro' | 'mort' | 'other'

export default defineComponent({
  name: 'Surveys',
  components: {
    Button,
    Spin,
    SurveysFilters,
    Header,
  },
  async mounted() {
    await this.$store.dispatch('surveysFilter/updateData');
    await this.$store.dispatch('surveysMortality/updateData');
  },
  data() {
    return {
      filterClicked: true,
    };
  },
  computed: {
    getFilterAmount():number {
      return this.$store.getters[`${this.getModule}/getFilterAmount`];
    },
    getLoadingState(): string {
      return this.$store.getters[`${this.getModule}/getLoading`];
    },
    getModule():string {
      const indicator = this.getState;
      let mod = 'surveysFilter';
      if (indicator === 'mort') {
        mod = 'surveysMortality';
      } else {
        mod = 'surveysFilter';
      }
      return mod;
    },
    getState(): pages {
      if (this.$route?.name?.toString().includes('Mortality')) {
        return 'mort';
      }
      return 'anthro';
    },
  },
  methods: {
    changeIndicator() {
      this.clearAll();
    },
    clearAll() {
      const mod = this.getModule;
      this.$store.dispatch(`${mod}/updateLocation`, '');
      this.$store.dispatch(`${mod}/updateAge`, '');
      this.$store.dispatch(`${mod}/updateSeasonality`, '');
      this.$store.dispatch(`${mod}/updateSurveyType`, '');
      this.$store.dispatch(`${mod}/updateOrganization`, '');
      this.$store.dispatch(`${mod}/updateGeographicName`, '');
      this.$store.dispatch(`${mod}/updateDate`, []);
      this.$store.dispatch(`${mod}/updateData`);
    },
  },
  watch: {
    getLoadingState(newProps, oldProps) {
      if (oldProps === 'loading' && newProps === 'done') {
        message.destroy();
      } else if (newProps === 'loading') {
        message.loading('Loading data and running calculations...', 0);
      }
    },
  },
});

