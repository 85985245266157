
import { Select } from 'ant-design-vue';
import { defineComponent } from 'vue';
import FilterSelection from './FilterSelection.vue';

const { Option } = Select;

export default defineComponent({
  name: 'OrganizationFilter',
  components: {
    FilterSelection,
    Option,
  },
  props: {
    module: {
      type: String,
      require: true,
      default: 'surveysFilter',
    },
    orgList: {
      type: Array,
      require: true,
      default: [''] as string[],
    },
  },
  methods: {
    selectItem(key: string) {
      this.$store.dispatch(`${this.module}/updateOrganization`, key);
    },
    clear() {
      this.$store.dispatch(`${this.module}/updateOrganization`, '');
      this.$emit('clear');
    },
  },
  computed: {
    getOrganization():string {
      return this.$store.getters[`${this.module}/getOrganization`];
    },
  },
  emits: ['clear'],
});
