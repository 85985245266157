
import { defineComponent, PropType } from 'vue';
import { Select } from 'ant-design-vue';
import FilterSelection from './FilterSelection.vue';

const { Option } = Select;

export default defineComponent({
  name: 'AgeGroupFilter',
  components: {
    FilterSelection,
    Option,
  },
  props: {
    ages: {
      type: Array as PropType<string[]>,
      default: ['0-59 months', '6-59 months', 'All'] as string[],
    },
    module: {
      type: String,
      require: true,
      default: 'surveysFilter',
    },
  },
  computed: {
    getAge() {
      const age = this.$store.getters[`${this.module}/getAge`];
      return age;
    },
  },
  methods: {
    select(key : string): void {
      if (key === 'All') {
        this.clear();
      } else {
        this.$store.dispatch(`${this.module}/updateAge`, key);
      }
    },
    clear() {
      this.$store.dispatch(`${this.module}/updateAge`, '');
      this.$emit('clear');
    },
  },
  emits: ['clear'],
});
