import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Option = _resolveComponent("Option")!
  const _component_FilterSelection = _resolveComponent("FilterSelection")!

  return (_openBlock(), _createBlock(_component_FilterSelection, {
    label: "AGE GROUP",
    state: _ctx.getAge || undefined,
    defaultName: _ctx.getAge || 'All',
    onClear: _ctx.clear,
    onSelect: _ctx.select
  }, {
    overlay: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ages, (age) => {
        return (_openBlock(), _createBlock(_component_Option, { key: age }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(age), 1)
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }, 8, ["state", "defaultName", "onClear", "onSelect"]))
}